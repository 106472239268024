"use client"
import classNames from "classnames"
import Carousel from "react-bootstrap/Carousel"
import { useValidateMobile } from "hooks/validateMobile"
import LocalImage from "../LocalImage"

import styles from "./HomepageTestimonials.module.scss"

const HeaderTestimonials = ({ slides }) => {
  const { isMobile } = useValidateMobile(1200)

  return (
    <div className={styles.wrapper}>
      <Carousel fade controls={false}>
        {slides?.map(slide => {
          const {
            image,
            quote,
            author,
            title,
            company,
            logo,
            priority,
            imageMobile,
          } = slide
          return (
            <Carousel.Item key={author}>
              <div className={styles.slide}>
                <div className={styles.testimonial}>
                  {!isMobile && (
                    <div className={classNames(styles.image, styles.image__lg)}>
                      <LocalImage
                        alt="RepVue"
                        src={image}
                        width={268}
                        height={268}
                        priority={priority}
                      />
                    </div>
                  )}
                  {isMobile && (
                    <div className={classNames(styles.image, styles.image__md)}>
                      <LocalImage
                        alt="RepVue"
                        src={imageMobile || image}
                        width={50}
                        height={50}
                      />
                      <LocalImage alt="RepVue" src={logo} />
                    </div>
                  )}
                  <div className={styles.quote}>
                    <div className={styles.info}>
                      <div className={styles.info__image}>
                        <LocalImage alt="RepVue" src={logo} />
                      </div>
                      <div className={styles.info__wrapper__xl}>
                        <div className={styles.author}>{author}</div>
                        <div
                          className={styles.status}
                        >{`${title}, ${company}`}</div>
                      </div>
                    </div>
                    <span>{quote}</span>
                    <div className={styles.info__wrapper__lg}>
                      <div className={styles.author}>{author}</div>
                      <div className={styles.position}>{title}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </div>
  )
}

export default HeaderTestimonials
