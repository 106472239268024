import Meta from "components/shared/Head/Meta"

const HomeMeta = () => {
  const title = `RepVue | Sales Organization Ratings, Reviews, Jobs, and Salary Data`
  const description = `RepVue allows you to get the inside scoop on the world’s most well-known sales organizations with ratings, reviews, salary data, culture scores, lead flow scores, and more.`
  const url = `${process.env.NEXT_PUBLIC_URL}`

  return (
    <Meta
      description={description}
      title={title}
      url={url}
      twitterTitle={title}
    />
  )
}

export default HomeMeta
