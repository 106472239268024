/* eslint-disable sonarjs/no-duplicate-string */
import karanBilimaria from "components/shared/Carousels/CarouselSlides/1_karan_bilimaria.webp"
import karanBilimariaMobile from "components/shared/Carousels/CarouselSlides/1_karan_bilimaria-mobile.webp"
import dennyLowe from "components/shared/Carousels/CarouselSlides/2_denny_lowe.webp"
import andrewTrick from "components/shared/Carousels/CarouselSlides/3_andrew_trick.webp"
import deniseCushaney from "components/shared/Carousels/CarouselSlides/4_denise_cushaney.webp"
import kelseyRae from "components/shared/Carousels/CarouselSlides/5_kelsey_rae_beatty.webp"
import gabrielleReyson from "components/shared/Carousels/CarouselSlides/6_gabrielle_reyson.webp"
import kellyCone from "components/shared/Carousels/CarouselSlides/kelly_cone.webp"
import haileyGoldberg from "components/shared/Carousels/CarouselSlides/hailey-goldberg.webp"
import haileyGoldbergMobile from "components/shared/Carousels/CarouselSlides/hailey-goldberg-mobile.webp"
import juanArcila from "components/shared/Carousels/CarouselSlides/juan-arcila.webp"
import gartnerLogo from "components/shared/Carousels/CarouselSlides/gartner_logo.webp"
import dataDogLogo from "components/shared/Carousels/CarouselSlides/datadog_logo.webp"
import hubSpotLogo from "components/shared/Carousels/CarouselSlides/hubspot_logo.webp"
import oktaLogo from "components/shared/Carousels/CarouselSlides/okta_logo.webp"
import userGemsLogo from "components/shared/Carousels/CarouselSlides/user_gems_logo.webp"
import ryanBarretto from "components/shared/Carousels/CarouselSlides/ryan_barretto.webp"
import sproutSocialLogo from "components/shared/Carousels/CarouselSlides/sprout_social_logo.webp"
import rileyStefano from "components/shared/Carousels/CarouselSlides/riley_stefano.webp"
import joshRoth from "components/shared/Carousels/CarouselSlides/josh_roth.webp"
import lobLogo from "components/shared/Carousels/CarouselSlides/lob_logo.webp"
import michelleBenfer from "components/shared/Carousels/CarouselSlides/michelle_benfer.webp"
import nikkiAzodi from "components/shared/Carousels/CarouselSlides/nikkiAzodi.webp"
import upKeepLogo from "components/shared/Carousels/CarouselSlides/upKeep_logo.webp"
import consensusLogo from "components/shared/Carousels/CarouselSlides/consensus_logo.webp"
import RyanFair from "components/shared/Carousels/CarouselSlides/ryan_fair.webp"
import samsaraLogo from "components/shared/Carousels/CarouselSlides/samsara.webp"
import danSummers from "components/shared/Carousels/CarouselSlides/dan_summers.webp"
import kendraDupree from "components/shared/Carousels/CarouselSlides/kendra_dupree.webp"
import scottSmith from "components/shared/Carousels/CarouselSlides/scott_smith.webp"
import redgateLogo from "components/shared/Carousels/CarouselSlides/redgate.webp"
import carolinaHandling from "components/shared/Carousels/CarouselSlides/carolina_handling.webp"

export const EMPLOYER_SLIDES = [
  {
    image: karanBilimaria,
    body: `My decision to take a mid-market account executive role at Okta was a no brainer when I reviewed the RepVue data to check how Okta stacked up against other offers I had.`,
    name: "Karan Bilimoria",
    title: "Account Executive",
    company: "Okta",
  },
  {
    image: dennyLowe,
    body: `I love the transparency that RepVue provides on things that are critical to sales professionals. It also validates what a great organization Mulesoft is.`,
    name: "Denny Lowe",
    title: "Strategic Account Executive",
    company: "MuleSoft",
  },
  {
    image: andrewTrick,
    body: `RepVue was really helpful in my decision making process in terms of identifying which companies weren’t a good fit and allowing me to find Klaviyo, which ticked all the boxes I was looking for.`,
    name: "Andrew Trick",
    title: "BDR",
    company: "Klaviyo",
  },
  {
    image: deniseCushaney,
    body: `Data available on RepVue can confirm what you're hearing from recruiters before you invest a lot of time interviewing. RepVue helped with my search and now new role at Gartner.`,
    name: "Denise Cushaney",
    title: "Sr. Account Executive",
    company: "Gartner",
  },
  {
    image: kelseyRae,
    body: `I never would have heard of or trusted Movable Ink without the validation of RepVue. I was ready to abandon tech sales altogether before using RepVue.`,
    name: "Kelsey Rae Beatty",
    title: "Business Development Representative",
    company: "Movable Ink",
  },
  {
    image: gabrielleReyson,
    body: `I was blessed to get the best job of my life by submitting the simplest application anywhere using the greatest platform in the industry!`,
    name: "Gabrielle Reyson",
    title: "Senior ADR",
    company: "Usergems",
  },
]

export const HOMEPAGE_TESTIMONIAL_SLIDES = [
  {
    image: haileyGoldberg,
    imageMobile: haileyGoldbergMobile,
    quote: `"I used RepVue constantly during my job search, ultimately helping to land my new role at HubSpot. I could not be happier here and grateful that a trusted and reliable source like RepVue exists.”`,
    author: "Hailey Goldberg",
    title: "Corporate AE",
    company: "HubSpot",
    logo: hubSpotLogo,
    priority: true,
  },
  {
    image: karanBilimaria,
    imageMobile: karanBilimariaMobile,
    quote: `"My decision to take a mid-market account executive role at
    Okta was a no brainer when I reviewed the RepVue data to check
    how Okta stacked up against other offers I had."`,
    author: "Karan Bilimoria",
    title: "Account Executive",
    company: "Okta",
    logo: oktaLogo,
  },
  {
    image: kellyCone,
    quote: `“It almost felt like I knew somebody on the inside that was advocating for me before I even applied to a role.”`,
    author: "Kelly Cone",
    title: "Enterprise Account Executive",
    company: "Consensus",
    logo: consensusLogo,
  },
  {
    image: deniseCushaney,
    quote: `"Data available on RepVue can confirm what you're hearing from recruiters before you invest a lot of time interviewing. RepVue helped with my search and now new role at Gartner."`,
    author: "Denise Cushaney",
    title: "Sr. Account Executive",
    company: "Gartner",
    logo: gartnerLogo,
  },
  {
    image: juanArcila,
    quote: `"RepVue was pivotal to my job search process. I looked to see how the company ranked among fellow sales reps, quota attainment, and company culture."`,
    author: "Juan Arcila",
    title: "Account Executive",
    company: "DataDog",
    logo: dataDogLogo,
  },
  {
    image: gabrielleReyson,
    quote: `"I was blessed to get the best job of my life by submitting the simplest application anywhere using the greatest platform in the industry!"`,
    author: "Gabrielle Reyson",
    title: "Senior ADR",
    company: "Usergems",
    logo: userGemsLogo,
  },
  {
    image: RyanFair,
    quote: `"RepVue was the essential ingredient missing in previous job searches that I used this time around to land an incredible job. Truly makes a difference to get an honest scorecard of each company.”`,
    author: "Ryan Fair",
    title: "Commercial ADR",
    company: "Samsara",
    logo: samsaraLogo,
  },
]

export const EMPLOYER_TOP_TESTIMONIAL_SLIDES = [
  {
    image: ryanBarretto,
    quote: `"We put a lot of effort into building a world class sales org.  RepVue helps us tell our story to a very targeted audience of salespeople looking for their next role. We’ve hired 5 critical sales roles in just a few months of working with RepVue."`,
    author: "Ryan Barretto",
    title: "President",
    company: "Sprout Social",
    logo: sproutSocialLogo,
  },
  {
    image: kendraDupree,
    quote: `We’re absolutely hooked on RepVue! With over a decade in the employer branding game, I can confidently say this platform delivers the most authentic data I’ve ever seen.`,
    author: "Kendra Duprèe",
    title: "Global Talent Acquisition Partner",
    company: "Redgate Software",
    logo: redgateLogo,
  },
  {
    image: nikkiAzodi,
    quote: `"We used RepVue to hire an SDR and a Territory Manager, which is one of our more difficult sales roles to fill. Many of our candidates mention RepVue as part of their journey in finding UpKeep. So far, RepVue has delivered qualified candidates and amazing results!"`,
    author: "Nikki Azodi",
    title: "Recruiter",
    company: "UpKeep",
    logo: upKeepLogo,
  },
  {
    image: scottSmith,
    quote: `A large portion of our hires came directly from RepVue applicants. But say that 30% of the applicants applied directly on RepVue, 90% of the applicants reviewed our RepVue profile prior to the interview process, which sets us up for interview success and forward momentum.`,
    author: "Scott Smith",
    title: "Sales Manager",
    company: "Carolina Handling",
    logo: carolinaHandling,
  },
  {
    image: danSummers,
    quote: `I think one of the most compelling and exciting stats is that we are seeing a faster time to hire with RepVue candidates, roughly 2x the speed of the average time to hire a sales person. In our own internal data, RepVue candidates are converting 50% higher than other folks.`,
    author: "Dan Summers",
    title: "VP of Revenue Operations and Strategy",
    company: "Sprout Social",
    logo: sproutSocialLogo,
  },
]

export const EMPLOYER_BOTTOM_TESTIMONIAL_SLIDES = [
  {
    image: scottSmith,
    body: `A large portion of our hires came directly from RepVue applicants. But say that 30% of the applicants applied directly on RepVue, 90% of the applicants reviewed our RepVue profile prior to the interview process, which sets us up for interview success and forward momentum.`,
    name: "Scott Smith",
    title: "Sales Manager",
    company: "Carolina Handling",
    logo: carolinaHandling,
  },
  {
    image: danSummers,
    body: `I think one of the most compelling and exciting stats is that we are seeing a faster time to hire with RepVue candidates, roughly 2x the speed of the average time to hire a sales person. In our own internal data, RepVue candidates are converting 50% higher than other folks.`,
    name: "Dan Summers",
    title: "VP of Revenue Operations and Strategy",
    company: "Sprout Social",
    logo: sproutSocialLogo,
  },
  {
    image: kendraDupree,
    body: `We’re absolutely hooked on RepVue! With over a decade in the employer branding game, I can confidently say this platform delivers the most authentic data I’ve ever seen. RepVue has cultivated a trustworthy brand that empowers employees to share honest feedback about their employers. It’s revolutionized our talent attraction efforts and provided invaluable insights into areas for internal growth. This tool is a total game changer for us.`,
    name: "Kendra Duprèe",
    title: "Global Talent Acquisition Partner",
    company: "Redgate Software",
    logo: redgateLogo,
  },
  {
    image: joshRoth,
    body: `We hired a well known sales recruiting agency and cut them because we get better candidates through RepVue. Salespeople want transparency when they’re looking for a new role and RepVue helps us provide transparency.`,
    name: "Josh Roth",
    title: "VP of Commercial Sales",
    company: "Lob",
    logo: lobLogo,
  },
  {
    image: ryanBarretto,
    body: `We put a lot of effort into building a world class sales org.  RepVue helps us tell our story to a very targeted audience of salespeople looking for their next role. We’ve hired 5 critical sales roles in just a few months of working with RepVue.`,
    name: "Ryan Barretto",
    title: "President",
    company: "Sprout Social",
    logo: sproutSocialLogo,
  },
  {
    image: rileyStefano,
    body: `We care a lot about our employer brand and are constantly coming up with ways to attract and retain talent.  RepVue aligns with our goals of transparency and what it’s really like to be part of our sales team. RepVue has helped us influence 100s of sales candidates.`,
    name: "Riley Stefano",
    title: "Team Lead, Recruitment Marketing",
    company: "Datadog",
    logo: dataDogLogo,
  },
  {
    image: michelleBenfer,
    body: `We are super fans of what RepVue does for reps and the transparency it brings to earning potential and health of sales orgs for sales candidates. We know RepVue will be the first stop for many candidates considering us.`,
    name: "Michelle Benfer",
    title: "SVP of Sales",
    company: "HubSpot",
    logo: hubSpotLogo,
  },
  {
    image: nikkiAzodi,
    body: `We used RepVue to hire an SDR and a Territory Manager, which is one of our more difficult sales roles to fill. Many of our candidates mention RepVue as part of their journey in finding UpKeep. So far, RepVue has delivered qualified candidates and amazing results!`,
    name: "Nikki Azodi",
    title: "Recruiter",
    company: "UpKeep",
    logo: upKeepLogo,
  },
]
