import dynamic from "next/dynamic"
import usePageCall from "hooks/usePageCall"
import { useState } from "react"
import { HOMEPAGE_TESTIMONIAL_SLIDES } from "components/shared/Carousels/slides"
import { getLayout } from "components/layout"
import { trackAction } from "utils/analytics"
import { CLICKED } from "constants/track-actions"
import { Container, Row, Col } from "react-bootstrap"
import useVisibleElement from "hooks/useVisibleElement"

const Transparency = dynamic(() => import("components/home/Transparency"))
const Hiring = dynamic(() => import("components/home/Hiring"))
const MarketingFooter = dynamic(() => import("components/marketingFooter"))
const Why = dynamic(() => import("components/home/Why"))
const SearchModal = dynamic(() => import("components/companies/search"))

const Testimonials = dynamic(() =>
  import("components/shared/Carousels/Testimonials")
)

import HeaderTestimonials from "components/shared/Carousels/HeaderTestimonials"
import Header from "components/shared/Headers"
import HomeCompanies from "components/home/Companies"
import HomeMeta from "components/home/Head"
import Button from "components/shared/Button"
import Link from "next/link"
import { EMPLOYER_SLIDES } from "components/shared/Carousels/slides"

import styles from "./index.module.scss"

const Home = ({ companies, showReppys }) => {
  const [setRef, visibleElements] = useVisibleElement({
    root: null,
    rootMargin: "150px",
    threshold: 0.5,
  })

  const [showSearchModal, setShowSearchModal] = useState(false)
  usePageCall("Marketing", "Home")

  const handleSearchOpen = () => {
    setShowSearchModal(true)
  }

  const handleSearchClose = () => {
    setShowSearchModal(false)
  }

  return (
    <>
      <HomeMeta />
      <div className={styles.home}>
        <Header>
          <div className={styles.header}>
            <Container>
              <Row className="pb-xl-3">
                <Col xl={{ span: 8, offset: 1 }}>
                  <h1 className={styles.title}>
                    Sell for a Company You&nbsp;Love
                  </h1>
                  <p className={styles.description__lg}>
                    Powered by the collective knowledge of thousands of vetted
                    sales professionals, RepVue empowers you to make better
                    career decisions with transparent insights into sales orgs
                    you can&apos;t find elsewhere.
                  </p>
                  <div className={styles.buttonWrapper__lg}>
                    <Link
                      href="/rating/step1"
                      onClick={() => {
                        trackAction(CLICKED, {
                          location: "Home Hero",
                          click_text: "Add a Rating, Access Everything",
                          click_url: `${process.env.NEXT_PUBLIC_API_URL}/rating/step1`,
                          modal_name: null,
                        })
                      }}
                    >
                      <Button variant="primary">
                        Add a Rating, Access Everything
                      </Button>
                    </Link>
                  </div>
                </Col>
                <Col xl={8}>
                  <HeaderTestimonials slides={HOMEPAGE_TESTIMONIAL_SLIDES} />
                  <div className={styles.search__md} onClick={handleSearchOpen}>
                    <Link
                      prefetch={false}
                      href="/rating/step1"
                      onClick={() => {
                        trackAction(CLICKED, {
                          location: "Home Hero",
                          click_text: "Rate a Company to Get Started",
                          click_url: `${process.env.NEXT_PUBLIC_API_URL}/rating/step1`,
                          modal_name: null,
                        })
                      }}
                    >
                      <Button variant="primary">
                        Rate a Company to Get Started
                      </Button>
                    </Link>
                  </div>
                  <p className={styles.description__md}>
                    Powered by the collective knowledge of thousands of vetted
                    sales professionals, RepVue empowers you to make better
                    career decisions with transparent insights into sales orgs
                    you can&apos;t find elsewhere.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </Header>
        <div className={styles.wrapper}>
          <Container fluid="lg">
            <Row>
              <Col lg={{ span: 16, offset: 1 }}>
                <HomeCompanies companies={companies} />

                <article ref={setRef("transparency")}>
                  {visibleElements["transparency"] && <Transparency />}
                </article>
              </Col>
            </Row>
          </Container>
          <div className={styles.testimonialsWrapper}>
            <Container fluid="lg">
              <Row className="px-1">
                <Col
                  lg={{ span: 16, offset: 1 }}
                  className={`${styles.wrapper}`}
                >
                  <article ref={setRef("testimonials")}>
                    {visibleElements["testimonials"] && (
                      <Testimonials slides={EMPLOYER_SLIDES} home={true} />
                    )}
                  </article>
                </Col>
              </Row>
            </Container>
          </div>
          <article ref={setRef("why")}>
            {visibleElements["why"] && <Why />}
          </article>

          <Container fluid="lg">
            <Row>
              <Col lg={{ span: 16, offset: 1 }} className={styles.wrapper}>
                <article ref={setRef("hiring")}>
                  {visibleElements["hiring"] && <Hiring />}
                </article>
              </Col>
            </Row>
          </Container>

          <article ref={setRef("marketing")}>
            {visibleElements["marketing"] && <MarketingFooter />}
          </article>
        </div>
      </div>

      <SearchModal
        show={showSearchModal}
        handleClose={handleSearchClose}
        location="Home Search Results"
      />
    </>
  )
}

export async function getStaticProps() {
  const companiesRes = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/companies/home_page`
  )
  const companies = await companiesRes.json()

  const showReppys = false

  return {
    props: {
      companies,
      showReppys,
    },
  }
}

export default Home

Home.getLayout = getLayout
