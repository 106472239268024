import styles from "./Pills.module.scss"

const Pills = ({
  companies,
  setSelectedIndustry,
  setSelectedIndustryIndex,
  selectedIndustryIndex,
}) => (
  <div className={styles.pills}>
    {Object.keys(companies).map((industry, index) => (
      <div
        key={industry}
        className={`${styles.pill} ${
          index === selectedIndustryIndex ? styles.active : ""
        }`}
        onClick={() => {
          setSelectedIndustryIndex(index)
          setSelectedIndustry(Object.keys(companies)[index])
        }}
      >
        {industry.split(" ")[0]}
      </div>
    ))}
  </div>
)

export default Pills
