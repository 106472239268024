"use client"
import { useEffect, useCallback, useMemo } from "react"
import { pageCall } from "utils/analytics"
import { debounce } from "utils/common"

const usePageCall = (category, action, data = {}) => {
  const sendPageCall = useCallback((category, action, data) => {
    pageCall(category, action, data)
  }, [])

  const debouncePageCall = useMemo(
    () => debounce(sendPageCall, 200),
    [sendPageCall]
  )

  useEffect(() => {
    debouncePageCall(category, action, data)
  }, [])
}

export default usePageCall
