import { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { trackAction } from "utils/analytics"
import { CLICKED } from "constants/track-actions"
import Link from "next/link"
import Image from "next/image"
import Pills from "./Pills"

import styles from "./Companies.module.scss"

const HomeCompanies = ({ companies }) => {
  const [selectedIndustryIndex, setSelectedIndustryIndex] = useState(0)
  const [selectedIndustry, setSelectedIndustry] = useState(
    Object.keys(companies)[0]
  )

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col lg={9}>
          <div className={styles.mobilePills}>
            <Pills
              companies={companies}
              setSelectedIndustry={setSelectedIndustry}
              setSelectedIndustryIndex={setSelectedIndustryIndex}
              selectedIndustryIndex={selectedIndustryIndex}
            />
          </div>
          <div className={styles.companyCards}>
            {companies[selectedIndustry].map(company => (
              <Link
                href={`/companies/${company.slug}`}
                key={company.id}
                className={styles.card}
                onClick={() => {
                  trackAction("Company Clicked", {
                    company_name: company.name,
                    location: "Homepage Carousel",
                  })
                }}
              >
                <div className={styles.title}>{company.name}</div>
                <div className={styles.logo}>
                  <Image
                    src={company.logo}
                    height={65}
                    width={65}
                    alt={company.name}
                  />
                </div>
                <div className={styles.scoreTitle}>RepVue Score</div>
                <div className={styles.scoreNumber}>{company.repvue_score}</div>
              </Link>
            ))}
          </div>
        </Col>
        <Col lg={9}>
          <div className={styles.topLink}>Sales Organization Transparency</div>
          <div className={styles.bigTitle}>
            RepVue is a sales professional&apos;s best friend
          </div>
          <div className={styles.description}>
            {`With more verified sales organization ratings than any other platform, our users can access an insider's view of sales organizations and gain insights and data from those who know them best - the team members who work there.`}
          </div>
          <div className={styles.button}>
            <Link href="/companies">
              <Button
                onClick={() => {
                  trackAction(CLICKED, {
                    location: "Home Carousel",
                    click_text: "Explore Sales Orgs",
                    click_url: `${process.env.NEXT_PUBLIC_URL}/companies`,
                    modal_name: null,
                  })
                }}
              >
                Explore Sales Orgs
              </Button>
            </Link>
          </div>
          <div className={styles.desktopPills}>
            <Pills
              companies={companies}
              setSelectedIndustry={setSelectedIndustry}
              setSelectedIndustryIndex={setSelectedIndustryIndex}
              selectedIndustryIndex={selectedIndustryIndex}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HomeCompanies
